// This file is generated from clients/[client]/app/menu.ts

export const menu = [
    {
        title: 'Mein Briefing',
        url: '/my-briefing',
        icon: 'checkmark-circle',
    },
    {
        title: 'Community',
        url: '/walls/45cc9dbe-6730-4b49-91ab-6bd6f5873b53',
        icon: 'people',
    },
    {
        title: 'Social',
        url: '/social',
        icon: 'chatbubbles',
    },
    {
        title: 'Aktionen',
        url: '/activities',
        icon: 'rocket',
    },
    {
        title: 'Veranstaltungen',
        url: '/events',
        icon: 'calendar',
    },
    {
        title: 'FAQ',
        url: '/faq',
        icon: 'bulb',
    },
    {
        title: 'Badges',
        url: '/badges',
        icon: 'ribbon',
    },
    {
        title: 'Held:innen',
        url: '/heroes',
        icon: 'trophy',
    },
    {
        title: 'Toolbox',
        url: '/toolbox',
        icon: 'hammer',
    },
    {
        title: 'Feedback',
        // Needs to be kept in sync with feedback_email in config.yml
        url: 'mailto:neos2go@neos.eu',
        target: '_self',
        icon: 'mail',
        hideOnMobile: true,
    },
];
